import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import {rtdbPlugin} from 'vuefire'
axios.defaults.baseURL = 'https://api.iha.co.id/api'

import './index.css'
import './assets/icons/icomoon/styles.min.css'

require('@/store/subscribe')
Vue.config.productionTip = false
Vue.use(rtdbPlugin)

store.dispatch('auth/setToken', localStorage.getItem('token'))
.then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
